html[data-theme='dark'] a,
html[data-theme='dark'] button {
  border: none !important;
  box-shadow: none !important;
}
html[data-theme='dark'] .list[data-mode='zigzag'] .item .description {
  background: var(--h-color-background-level-2);
  border: none;
}
html[data-theme='dark'] .permalink > .content .contents_style hr {
  filter: brightness(0) invert(1);
}
html[data-theme='dark'] .permalink > .content .contents_style figure[data-ke-type=opengraph] .og-image {
  border: none !important;
  box-shadow: none !important;
}
html[data-theme='dark'] .permalink > .content .contents_style figure.fileblock {
  border: none !important;
  background-color: var(--h-color-background-level-2);
}
html[data-theme='dark'] .permalink > .content .contents_style figure.fileblock .desc .filename,
html[data-theme='dark'] .permalink > .content .contents_style figure.fileblock .desc .size {
  color: var(--h-color) !important;
}
html[data-theme='dark'] .permalink > .content .contents_style figure.fileblock .image,
html[data-theme='dark'] .permalink > .content .contents_style figure.fileblock a::after {
  filter: brightness(0) invert(1);
}
html[data-theme='dark'] .permalink > .content .contents_style figure[data-ke-type=opengraph] .og-text {
  background: var(--h-color-background-level-2);
}
html[data-theme='dark'] #__sidebar .tistory .header_layer .img_common_tistory {
  filter: brightness(0) invert(1);
}
html[data-theme='dark'] #tistoryCclLayer .bundle_ccl .txt_mark {
  filter: brightness(0) invert(1);
  color: var(--h-color);
  border: none;
}
html[data-theme='dark'] {
  --h-color: rgba(255,255,255,0.9);
  --h-color-blur: rgba(255,255,255,0.8);
  --h-color-blurrier: rgba(255,255,255,0.6);
  --h-color-blurriest: rgba(255,255,255,0.4);
  --h-color-background-level-1: #1e1f21;
  --h-color-background-level-2: #292a2d;
  --h-color-background-level-3: #353638;
  --h-color-background-level-4: rgba(30,31,33,0.6);
  --h-color-theme: #5db0d7;
  --h-button-color: rgba(255,255,255,0.9);
  --h-button-color-background: #353638;
  --h-button-color-hover: rgba(255,255,255,0.9);
  --h-button-color-hover-background: #353638;
}
